@import "./colors";

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: $background-color;
  overflow: hidden !important;
  width: 100%;
  padding: 0;
  position: fixed;
}
