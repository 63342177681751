@import "./sizes";

.mat-horizontal-stepper-header {
  pointer-events: none;
}

.mat-vertical-stepper-header {
  pointer-events: none;
}

.mat-mdc-card {
  width: 100% !important;
}

mat-form-field {
  width: 100%;
}

button {
  width: 100%;
  border-radius: 0.2rem;
  padding: 0.2rem;
}

.mat-badge {
  --mat-badge-background-color: red !important;
}

mat-divider {
  padding: 0.5rem;
}

mat-card-actions {
  display: flex;
  flex-direction: row;
  align-content: left;
}

.mat-mdc-card-avatar {
  margin-bottom: 0px !important;
}

mat-stepper {
  background: transparent;
  overflow: hidden;

  .mat-stepper-horizontal-line {
    min-width: 4px;
  }

  .mat-step-icon {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  mat-step {
    max-width: 30rem;
    min-width: 30rem;

    margin-left: auto;
    margin-right: auto;

    @include respond-to(small) {
      max-width: 20rem;
      min-width: 20rem;
    }
  }

  .mat-horizontal-stepper-header-container {
    display: flex;
    align-content: center;
    justify-content: center;
    max-width: 85rem;
    min-width: 30rem;

    margin-left: auto;
    margin-right: auto;

    @include respond-to(small) {
      max-width: auto;
      min-width: auto;
    }
  }
}
