/* sizes.scss */

/* Define your breakpoints */
$screen-small: 500px;
$screen-medium: 768px;
$screen-large: 1024px;

/* Mixin for media queries */
@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media screen and (max-width: $screen-small) {
      @content;
    }
  } @else if $breakpoint == medium {
    @media screen and (max-width: $screen-medium) {
      @content;
    }
  } @else if $breakpoint == large {
    @media screen and (max-width: $screen-large) {
      @content;
    }
  }
}
